import React from 'react'

import './App.css';

// some files I have stored in s3 bucket ted-photo-objects
const fileNames = [
  "0539294A-A8C7-49F3-BD40-4B4D3D678358.jpeg",
  "0B79698C-EB60-4992-AFEF-A8A123D0F782.jpeg",
  "190A72FB-67EB-4EB7-AA41-262737722403.jpeg",
  "1ABFE7BB-EDA2-41C9-93A9-200FE735E331.jpeg",
  "21A873E7-9B70-4CBC-8C03-BE01C4136BC4.jpeg",
  "24DB27C4-0808-40F2-80B5-5DBFFA5BA5AC.jpeg",
  "254B2B6D-A463-4614-A204-33618904AF5A.jpeg",
  "2731CD5A-778A-40EF-A92A-A36924B4FD8F.jpeg",
  "2EADEA8A-47C7-4BA0-97E5-4247EE514F91.jpeg",
  "3251AC36-10EF-4099-A91B-C1E7A30EB53A.jpeg",
  "3806AE96-C595-4A99-83E4-1BE5F389E548.jpeg",
  "3D92329C-72E8-4F26-B2BA-62659609E518.jpeg",
  "41A6CD45-091B-4455-9228-B79642913484.jpeg",
  "428483A6-6FF0-4870-8A7E-2D2103877DB5.jpeg",
  "492EFF0B-6BEB-4B5A-8636-5DE0C5F89EF0.jpeg",
  "512E9EA6-8250-44E0-BB08-E42F0A66B0E9.jpeg",
  "53054CB7-7E93-4584-9DD9-3D561C24B413.jpeg",
  "7820A020-7A84-4F8C-B9D4-0835A5807A2A.jpeg",
  "7A9CF775-41EF-4668-B1F5-6B7B2539B97B.jpeg",
  "7FAEEEE4-0A97-459A-AD07-93B1D06A4558.jpeg",
  "81E6BFCD-B061-4C63-B4C8-E0DE8E46BA7A.jpeg",
  "844850E5-3F5B-4A2A-A880-B6B5EFC9B3FB.jpeg",
  "896181F8-C9EC-4219-887C-2CCB561FCB52.jpeg",
  "8B764E3E-F4C4-4B07-A466-312A163743F4.jpeg",
  "8D46D5C7-0819-4ACE-904C-8754BEED85E7.jpeg",
  "92AABB93-E12F-4FBE-9539-D299D7E1C987.jpeg",
  "99A23784-E138-4781-AF55-40FA02790ECA.jpeg",
  "9DDDC8DB-0EEA-433D-8F6E-774B0C58B219.jpeg",
  "9F4DB79C-B2AB-4F3E-A995-3D54CD8711AB.jpeg",
  "9F62C0E6-BF3A-4AC9-BD46-EDBF55F4D91E.jpeg",
  "A169A105-1E3C-4883-93B9-D924BF8DE605.jpeg",
  "A4D5E4DF-9716-471F-802F-5419553AB1F9.jpeg",
  "A9861CB4-B336-41DA-ACA5-04C8C80741C9.jpeg",
  "AE3C3190-B399-4A84-8098-10151DF9BBBB.jpeg",
  "C2A8E36B-A1C9-4496-805A-9F4A9A22536A.jpeg",
  "C7531D7B-3DAD-4475-8CA6-FD661129819E.jpeg",
  "CB1C80BA-1AEE-463F-B247-DF56FCA3F2EC.jpeg",
  "D092ED4C-4DD6-4A67-879B-BB9547642111.jpeg",
  "D47B5C90-3694-47B5-BC3B-B84120662CBE.jpeg",
  "D56B79FC-99A3-4642-B9F3-B52E2AB9C10A.jpeg",
  "D8728D77-F6A6-4271-95AD-8C30DA9F86A3.jpeg",
  "E6DC24A6-9FB8-4738-8078-48EE855ADFEC.jpeg",
  "E7B5A6EA-E406-46ED-950D-7D9191709F5B.jpeg",
  "E8426425-1FE4-4FAE-BCD4-24BD80764B2A.jpeg",
  "EDEF8E8B-2422-468F-88CE-6CD940EBA208.jpeg",
  "EE6507E7-BF2C-4535-9169-9885F9DFAC8D.jpeg",
  "F0B82F4E-9643-4FC2-8436-2A6764F45E8C.jpeg",
  "F8C12489-FDB6-4E7C-A3A0-D6F6719E660D.jpeg",
  "FE8B6FD0-FCD4-4DFD-85DD-69DD578DC3D1.jpeg",
];

// in place
function shuffle(arr) {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

function App() {
  const shuffledKeys = shuffle(fileNames);
  const listItems = shuffledKeys.map(url => (
    <img key={url} className="element" alt="one of ted's many photogs" src={`https://ted-photo-objects.s3-us-west-2.amazonaws.com/${url}`}></img>
  ));

  return (
    <div className="grid">
      {listItems}
    </div>
  );
}

export default App;
